import IndexDescrItem, {
	IAuthDescrItemProps
} from '@uiComponents/IndexDescription/IndexDescrItem.tsx';

import cl from './indexDescription.module.scss';

const authContents: IAuthDescrItemProps[] = [
	{
		title: 'Как проходит сертификация',
		content: {
			type: 'ol',
			text: [
				'Проверяем проектную документацию и присваиваем класс жилья и грейд',
				'Очно оцениваем качество объекта, введенного в эксплуатацию',
				'Присваиваем категорию качества внутри класса'
			]
		}
	}
];
const registryContents: IAuthDescrItemProps[] = [
	{
		title: 'Почему стоит зарегистрироваться:',
		content: {
			type: 'ol',
			text: [
				'Попробуйте «Бесплатный тариф» - и узнайте предварительный класс своего объекта',
				'Пройдите сертификацию и сможете улучшить качество вашего объекта на этапе проекта',
				'Получите рекомендации по улучшению своего объекта'
			]
		}
	}
];

interface IIndexDescriptionProp {
	type: 'registry' | 'auth';
}

const IndexDescription = ({ type }: IIndexDescriptionProp) => {
	return (
		<div className={cl.container}>
			<div className={cl.title}>
				<span>Добро пожаловать в мир сертификации новостроек </span>
				<a href='https://urbangrade.ru/' target='_blank' rel='noreferrer'>
					Urban Grade
				</a>
			</div>

			<div className={cl.descr}>
				<span>
					Вы находитесь на платформе, которая предоставляет уникальную возможность
					сертифицировать новостройки и обеспечивать их соответствие высоким стандартам
					качества. Наша цель — помочь покупателям квартиры сделать осознанный выбор и
					гарантировать надежность застройщиков.
				</span>
				{/*<span>Сотрудники помогут в процессе и ещё какой-то текст потом придумать</span>*/}
			</div>

			<div className={cl.contentWrapper}>
				{type === 'auth' &&
					authContents.map(c => (
						<IndexDescrItem title={c.title} content={c.content} key={c.title} />
					))}

				{type === 'registry' &&
					registryContents.map(c => (
						<IndexDescrItem title={c.title} content={c.content} key={c.title} />
					))}
			</div>

			{type === 'registry' && (
				<div className={cl.descr}>
					<span>
						Заполните форму регистрации, чтобы начать использовать все преимущества
						нашего сервиса и внести вклад в улучшение качества новостроек!
					</span>
				</div>
			)}
		</div>
	);
};

export default IndexDescription;
