const TabNavItem = ({
    id,
    title,
    activeTab,
    setActiveTab
}: {
    id: string;
    title?: string;
    activeTab: string;
    setActiveTab: (tab: string) => void;
}) => {
    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <li onClick={handleClick} className={activeTab === id ? 'active' : ''}>
            {title}
        </li>
    );
};
export default TabNavItem;
