import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from '../../router';

const AppRouter = () => {

    return (
        <>
            <RouterProvider router={createBrowserRouter(routes)} />
        </>
    );
};

export default AppRouter;
