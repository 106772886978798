import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useQuery<T extends string>(param: string): T | undefined {
    const { search } = useLocation();

    return useMemo<T | undefined>(() => {
        const query = new URLSearchParams(search);
        return query.get(param) as T | undefined;
    }, [search, param]);
}
