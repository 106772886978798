import { Link, useRouteError } from 'react-router-dom';

import Logo from '@uiComponents/Logo/Logo.tsx';

import cl from './errorPage.module.scss';

import city from '@/assets/city-illustration.svg';

interface IRouterError {
	error: {
		message: string;
	};
	status: number;
	statusText: string;
}

interface IErrorPageProps {
	type: '404' | 'unauthorized';
}

const ErrorPage = ({ type }: IErrorPageProps) => {
	const error = useRouteError() as IRouterError;

	return (
		<div className={cl.errorPage}>
			{/*<h2>*/}
			{/*	Вы перешли на несущесвующую страницу <br />*/}
			{/*	Ошибка: {error?.statusText} <br />*/}
			{/*	Статус ошибки: {error?.status} <br />*/}
			{/*	Сообщение: {error?.error?.message} <br />*/}
			{/*</h2>*/}

			<img className={cl.backgroundImage} src={city} alt='Задний фон' />

			<div className={cl.logo}>
				<Logo />
			</div>

			<div className={cl.wrapper}>
				<h1 className={cl.title}>
					{type === '404' && (
						<>
							Кажется, такой страницы
							<br />
							не&nbsp;существует
						</>
					)}
					{type === 'unauthorized' && (
						<>
							Извините, у вас нет доступа
							<br />
							к&nbsp;этой&nbsp;странице
						</>
					)}
				</h1>

				<Link className={cl.link} to='/'>
					На главную
				</Link>
			</div>

			{type === '404' && <div className={cl.statusCode}>{error?.status}</div>}
		</div>
	);
};

export default ErrorPage;
