import { useState, useCallback } from 'react';
import $api from "../http";
import {LoginRequestData, PasswordlessConfirmRequest, PasswordlessStartRequest} from "@services/useAuthService.ts";

type HTTPRequestMethods = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
export type LoadingStatusOptions = 'idle' | 'loading' | 'error';
interface HTTPHeaders {
    [key: string]: string;
}
export const contentTypes = [
    'application/json; charset=utf-8',
    'image/webp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
    'application/x-zip-compressed',
    'application/octet-stream',
    // 'application/x-msdownload'
] as const;
export type ContentType = (typeof contentTypes)[number];
export const checkXisContentType = (x: any): x is ContentType => contentTypes.includes(x);

// Header Content-Type в ответе на файл не может быть application/json
const fileContentTypes =
    contentTypes.filter(ct => ct !== 'application/json; charset=utf-8');
export type FileContentType = (Exclude<ContentType, 'application/json; charset=utf-8'>)[number];
export const checkXisFileContentType = (x: any): x is ContentType => fileContentTypes.includes(x);

interface RequestConfig {
    url: string;
    method?: HTTPRequestMethods;
    body?: string | null | FormData | LoginRequestData | PasswordlessStartRequest | PasswordlessConfirmRequest;
    headers?: HTTPHeaders;
    responseType?: 'blob' | 'arraybuffer' | 'json',
}

export const useHttp = () => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatusOptions>('idle');
    const [error, setError] = useState<string | null>(null);

    const request = useCallback(
        async ({
                   url,
                   method = 'GET',
                   body = null,
                   headers = {'Content-Type' : 'application/json'},
                   responseType = 'json'
               }: RequestConfig) => {
            setLoadingStatus('loading');

            try{
                const response = await $api(url, {method, data: body, headers, responseType});

                if (response.statusText !== 'OK' && response.statusText !== 'Created') {
                    throw new Error (`Could not fetch ${url}, status: ${response.status}`)
                }

                const data = await response.data;
                const responseContentType: ContentType = await response.headers['content-type'];

                const responseData = {
                    data,
                    responseContentType
                }

                setLoadingStatus('idle');
                return responseData;
            } catch(e) {
                setLoadingStatus('error');
                throw e;
            }
        }, [])

    return { loadingStatus, request, setLoadingStatus }
}