import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { emailSchema } from '@components/Schemas/Schemas.ts';

import MyButton from '@uiComponents/MyButton/MyButton';
import MyInput from '@uiComponents/MyInput/MyInput';
import Tabs from '@uiComponents/Tabs/Tabs';

import { useShowErrorMessageEffect } from '@hooks/useShowErrorMessage.ts';
import { useShowLoadingStatusEffect } from '@hooks/useShowLoadingStatusEffect.tsx';

import { useAuthService } from '@services/useAuthService.ts';

import getDomainFromUrl from '@utils/getDomainFromUrl.ts';

import cl from './loginForm.module.scss';

import SessionLogin from '../SessionLogin/SessionLogin';

const LoginForm = ({
	clientId,
	redirectUrl,
	backRedirectUrl
}: {
	clientId: string;
	redirectUrl: string;
	backRedirectUrl?: string;
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [activeTab, setActiveTab] = useState<string>('');
	const [isCodeSended, setIsCodeSended] = useState<boolean>(false);
	const [code, setCode] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [isWrongEmail, setIsWrongEmail] = useState<boolean>(false);
	const [isWrongCredentials, setIsWrongCredentials] = useState(false);
	const [phone, setPhone] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const [availableAuthMethods, setAvailableAuthMethods] = useState<string[]>([]);

	const {
		getClientInfo,
		login: loginService,
		getRedirectLink,
		passwordlessConfirm,
		passwordlessStart,
		loadingStatus,
		setLoadingStatus
	} = useAuthService();

	useEffect(() => {
		(async () => {
			const response = await getClientInfo(clientId);
			setAvailableAuthMethods(response.authMethods);
		})();
	}, []);

	useShowLoadingStatusEffect(loadingStatus === 'loading', 'loginService');
	useShowErrorMessageEffect(loadingStatus === 'error', 'Не удалось войти');

	const login = async () => {
		switch (activeTab) {
			case 'email':
				await loginStrategy({ type: 'email', email, password });
				break;
			case 'phone':
				await loginStrategy({ type: 'phone', phone, password });
				break;
			case 'phone_code':
				await sendCodeToPhone(phone);
				break;
		}
	};

	const loginStrategy = async (
		cred: { password: string } & (
			| { type: 'email'; email: string }
			| { type: 'phone'; phone: string }
		)
	) => {
		try {
			const response = await loginService(clientId, { ...cred, password });

			setLoadingStatus('loading');
			window.location.replace(
				getRedirectLink(redirectUrl, {
					...response,
					redirectUrl: backRedirectUrl
				})
			);
		} catch (e) {
			setIsWrongCredentials(true);
		}
	};

	const sendCodeToPhone = async (phone: string) => {
		if (isCodeSended) {
			const response = await passwordlessConfirm(clientId, {
				realm: 'sms',
				username: phone,
				otp: code
			});

			return window.location.replace(
				getRedirectLink(redirectUrl, {
					...response,
					redirectUrl: backRedirectUrl
				})
			);
		}

		try {
			await passwordlessStart(clientId, {
				connection: 'sms',
				phone_number: phone
			});
			setIsCodeSended(true);
		} catch (e) {
			// if e is SpecifyRoleToAuthorize - redirect to reg form with inputed code
		}
	};

	const tabsItems = useMemo(() => {
		const tabs = [];

		if (availableAuthMethods.includes('email')) {
			tabs.push({
				id: 'email',
				// title: 'Почта',
				content: (
					<div className={cl.inputsWrapper}>
						<MyInput
							onChange={e => setEmail(e.target.value)}
							value={email}
							type='email'
							placeholder='Электронная почта'
							addClasses={cl.loginFormInput}
							isError={isWrongEmail}
						/>
						{isWrongEmail && (
							<div className={cl.errorMessage}>
								Неверный формат почты. Допустимый формат почты - example@gmail.com
							</div>
						)}

						<MyInput
							onChange={e => setPassword(e.target.value)}
							value={password}
							type='password'
							placeholder='Пароль'
							addClasses={cl.loginFormInput}
							isNeedToCheckCapsLock={true}
						/>
					</div>
				)
			});
		}

		if (availableAuthMethods.includes('phone')) {
			tabs.push({
				id: 'phone',
				title: 'Телефон',
				content: (
					<div className={cl.inputsWrapper}>
						<MyInput
							onChange={e => setPhone(e.target.value)}
							value={phone}
							type='phone'
							placeholder='Phone'
							addClasses={cl.loginFormInput}
						/>
						<MyInput
							onChange={e => setPassword(e.target.value)}
							value={password}
							type='password'
							placeholder='Password'
							addClasses={cl.loginFormInput}
						/>
					</div>
				)
			});
		}

		if (availableAuthMethods.includes('phone_code')) {
			tabs.push({
				id: 'phone_code',
				title: 'Код',
				content: (
					<div className={cl.inputsWrapper}>
						{isCodeSended ? (
							<MyInput
								onChange={e => setCode(e.target.value)}
								value={code}
								type='text'
								placeholder='Code'
								addClasses={cl.loginFormInput}
							/>
						) : (
							<MyInput
								onChange={e => setPhone(e.target.value)}
								value={phone}
								type='phone'
								placeholder='Phone'
								addClasses={cl.loginFormInput}
							/>
						)}
					</div>
				)
			});
		}

		if (!activeTab) {
			setActiveTab(tabs[0]?.id);
		}

		return tabs;
	}, [isWrongEmail, email, phone, code, password, activeTab, availableAuthMethods, isCodeSended]);

	return (
		<>
			<form className={cl.container} onSubmit={e => e.preventDefault()} noValidate={true}>
				<SessionLogin
					clientId={clientId}
					redirectUrl={redirectUrl}
					backRedirectUrl={backRedirectUrl}
				/>

				<div className={cl.title}>Войти в систему</div>

				<Tabs activeTab={activeTab} setActiveTab={setActiveTab} items={tabsItems} />

				{isWrongCredentials && (
					<div className={cl.errorMessage}>Введены неверные логин или пароль</div>
				)}

				<div className={cl.buttonsWrapper}>
					<MyButton
						addClasses={cl.loginFormButton}
						onClick={() => {
							emailSchema
								.validate(email)
								.then(() => {
									setIsWrongEmail(false);
									login();
								})
								.catch(() => setIsWrongEmail(true));
						}}
						disabled={email.length < 1 || password.length < 1}
					>
						Войти
					</MyButton>

					<div className={cl.bottomWrapper}>
						<span>Еще нет аккаунта? </span>
						<button
							type='button'
							className={cl.link}
							onClick={
								() =>
									backRedirectUrl &&
									window.location.replace(
										getDomainFromUrl(backRedirectUrl) + '/registry'
									)
								// backRedirectUrl && console.log(getDomainFromUrl(backRedirectUrl))
							}
						>
							Зарегистрироваться
						</button>
					</div>

					<a
						className={cl.buttonLink}
						onClick={e => {
							e.preventDefault();
							navigate(`/restore${location.search}`);
						}}
					>
						Забыли пароль
					</a>
				</div>
			</form>
		</>
	);
};

export default LoginForm;
