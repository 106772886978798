import {Id, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cl from './reactTostify.module.scss';

const stylesForToastMessage = {
    toastStyle: {
        borderRadius: '20px',
        padding: '20px 25px',
        fontSize: '15px',
        boxShadow: '0px 4px 44px 0px rgba(0, 0, 0, 0.25)',
        maxWidth: '455px'
    },
    bodyStyle: {
    },
    progressStyle: {
        backgroundColor: 'var(--main-black)',
        height: '3px'
    }
};

export const showToastMessage = (mess: string) => {
    toast.info(mess, {
        // toastId: 'toastInfo',
        containerId: 'toastInfo',
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1500,
        style: stylesForToastMessage.toastStyle,
        bodyStyle: stylesForToastMessage.bodyStyle,
        progressStyle: stylesForToastMessage.progressStyle
    });
};

export const showToastErrorMessage = (mess: string) => {
    toast.error(mess, {
        containerId: 'toastInfo',
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
        style: stylesForToastMessage.toastStyle,
        bodyStyle: stylesForToastMessage.bodyStyle,
        progressStyle: stylesForToastMessage.progressStyle
    });
};

const stylesForToastLoadingStatus = {
    style: {
        borderRadius: '20px',
        padding: '20px',
        fontSize: '15px',
        boxShadow: '0px 4px 44px 0px rgba(0, 0, 0, 0.25)',
        minHeight: '64px',
        width: 'fit-content',
        marginLeft: 'auto',
    },
    bodyStyle: {
        margin: 0,
        padding: 0
    },
    progressStyle: {
        backgroundColor: 'var(--main-black)',
        height: '3px'
    },
};

export const showLoadingStatus = (Spinner: JSX.Element, id: string): Id => {
    const el = <div style={{width: '20px', height: '20px'}}>{Spinner}</div>

    return toast.info(el, {
        toastId: id,
        containerId: 'toastLoading',
        autoClose: false,
        position: toast.POSITION.TOP_RIGHT,
        icon: false,
        hideProgressBar: true,
        closeButton: false,
        style: stylesForToastLoadingStatus.style,
        bodyStyle: stylesForToastLoadingStatus.bodyStyle,
        progressStyle: stylesForToastLoadingStatus.progressStyle,
        className: cl.Toastify__toastContainer
    });
};

export const closeLoadingStatus = (id: string) => toast.update(id, {autoClose: 1});