const CheckRoundConfirmedIcon = () => {
	return (
		<svg
			width='100'
			height='100'
			viewBox='0 0 100 100'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<mask
				id='mask0_3181_50898'
				style={{ maskType: 'luminance' }}
				maskUnits='userSpaceOnUse'
				x='6'
				y='6'
				width='88'
				height='88'
			>
				<path
					d='M50.0007 91.6667C55.4734 91.6734 60.8935 90.5987 65.9497 88.5044C71.0058 86.41 75.5982 83.3372 79.4632 79.4625C83.3378 75.5976 86.4106 71.0051 88.505 65.949C90.5994 60.8929 91.6741 55.4727 91.6673 50C91.6741 44.5273 90.5994 39.1072 88.505 34.051C86.4106 28.9949 83.3378 24.4025 79.4632 20.5375C75.5982 16.6628 71.0058 13.5901 65.9497 11.4957C60.8935 9.40129 55.4734 8.3266 50.0007 8.33334C44.528 8.3266 39.1078 9.40129 34.0517 11.4957C28.9956 13.5901 24.4031 16.6628 20.5382 20.5375C16.6635 24.4025 13.5907 28.9949 11.4963 34.051C9.40196 39.1072 8.32727 44.5273 8.33402 50C8.32727 55.4727 9.40196 60.8929 11.4963 65.949C13.5907 71.0051 16.6635 75.5976 20.5382 79.4625C24.4031 83.3372 28.9956 86.41 34.0517 88.5044C39.1078 90.5987 44.528 91.6734 50.0007 91.6667Z'
					fill='white'
					stroke='white'
					strokeWidth='4'
					strokeLinejoin='round'
				/>
				<path
					d='M33.334 50L45.834 62.5L70.834 37.5'
					stroke='black'
					strokeWidth='6'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</mask>
			<g mask='url(#mask0_3181_50898)'>
				<path d='M0 0H100V100H0V0Z' fill='#4ABC63' />
			</g>
		</svg>
	);
};

export default CheckRoundConfirmedIcon;
