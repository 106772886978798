import { ToastContainer } from 'react-toastify';

import Portal from '@uiComponents/Portal/Portal.tsx';

const Portals = () => {
	return (
		<>
			<Portal wrapperId='toast-info-portal'>
				<div data-no-click-outside={true}>
					<ToastContainer />
				</div>
			</Portal>
		</>
	);
};

export default Portals;
