import React from 'react';

const TabContent = ({
    id,
    activeTab,
    children
}: {
    id: string;
    activeTab: string;
    children: React.ReactNode;
}) => {
    return activeTab === id ? <div className="TabContent">{children}</div> : null;
};

export default TabContent;
