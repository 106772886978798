import Portals from '@components/App/Portals.tsx';

import pageModuleCSS from '@styles/page.module.scss';
import '@styles/styles.scss';

import cl from './app.module.scss';

import AppRouter from '../AppRouter/AppRouter.tsx';

function App() {
	return (
		<div className={cl.app}>
			<main className={pageModuleCSS.page}>
				<AppRouter />

				<Portals />
			</main>
		</div>
	);
}

export default App;
