import { ReactNode } from 'react';

import cl from './myButton.module.scss';

interface MyButtonProps {
	onClick?: () => void;
	children: ReactNode;
	addClasses?: string;
	disabled?: boolean;
}

const MyButton = ({ children, onClick, addClasses, disabled }: MyButtonProps) => {
	return (
		<button
			className={`${cl.myButton} ${addClasses ? addClasses : ''}`}
			onClick={() => (onClick ? onClick() : null)}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export default MyButton;
