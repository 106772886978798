import classNames from 'classnames';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import cl from './myInput.module.scss';

interface MyInputProps {
	placeholder: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	addClasses?: string;
	value: string;
	type: string;
	isNeedToCheckCapsLock?: boolean;
	isError?: boolean;
}

const MyInput = ({
	placeholder,
	addClasses,
	onChange,
	type,
	isNeedToCheckCapsLock,
	isError
}: MyInputProps) => {
	const [isCapslockPressed, setIsCapsLockPressed] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const checkOnCapslock = useCallback(
		(e: KeyboardEvent) => {
			if (isNeedToCheckCapsLock) {
				const caps = e.getModifierState && e.getModifierState('CapsLock');
				setIsCapsLockPressed(caps);
			}
		},
		[isNeedToCheckCapsLock]
	);

	useEffect(() => {
		if (inputRef?.current) {
			inputRef.current.addEventListener('keydown', checkOnCapslock);
			inputRef.current.addEventListener('keyup', checkOnCapslock);
		}
		return () => {
			if (inputRef?.current) {
				inputRef.current.removeEventListener('keydown', checkOnCapslock);
				inputRef.current.removeEventListener('keyup', checkOnCapslock);
			}
		};
	}, [checkOnCapslock]);

	return (
		<>
			{type === 'password' && isCapslockPressed && isNeedToCheckCapsLock && (
				<div className={cl.capslock}>Внимание: включён Caps-Lock</div>
			)}

			<input
				className={classNames(cl.myInput, addClasses, {[cl.error]: isError})}
				placeholder={placeholder}
				ref={inputRef}
				onChange={e => onChange(e)}
				type={type}
			/>
		</>
	);
};

export default MyInput;
