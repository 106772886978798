import { useEffect, useState } from 'react';

import { showToastErrorMessage } from '@uiComponents/ReactToastify/ReactToastify.tsx';

export const useShowErrorMessageEffect = (isError: boolean, errorMessage?: string) => {
	const [isNeedToShowError, setIsNeedToShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isError) {
			setIsNeedToShowError(true);
		} else {
			setIsNeedToShowError(false);
		}
	}, [isError]);

	useEffect(() => {
		if (isNeedToShowError && errorMessage) {
			showToastErrorMessage(errorMessage);
		}
	}, [isNeedToShowError, errorMessage]);
};
