import { useState } from 'react';

import { passwordSchema } from '@components/Schemas/Schemas.ts';

import MyButton from '@uiComponents/MyButton/MyButton';
import MyInput from '@uiComponents/MyInput/MyInput';

import CheckRoundConfirmedIcon from '@icons/CheckRoundConfirmedIcon/CheckRoundConfirmedIcon.tsx';

import { useShowErrorMessageEffect } from '@hooks/useShowErrorMessage.ts';

import { useAuthService } from '@services/useAuthService.ts';

import cl from './confirmForm.module.scss';
import { useShowLoadingStatusEffect } from "@hooks/useShowLoadingStatusEffect.tsx";

const ConfirmForm = ({
	clientId,
	realm,
	username,
	otp,
	redirectUrl
}: {
	clientId: string;
	realm: 'email' | 'sms';
	username: string;
	otp: string;
	redirectUrl: string;
}) => {
	const [password, setPassword] = useState<string>('');
	const [isWrongPassword, setIsWrongPassword] = useState<boolean>(false);
	const [redirectLink, setRedirectLink] = useState<string | null>(null);
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

	const { getRedirectLink, passwordlessConfirm, loadingStatus } = useAuthService();

	const confirm = async () => {
		try {
			const response = await passwordlessConfirm(clientId, {
				realm,
				username,
				otp,
				password
			});

			setRedirectLink(
				getRedirectLink(redirectUrl, {
					...response
				})
			);
			setIsConfirmed(true);
		} catch (e) {
			//
		}
	};

	useShowLoadingStatusEffect(loadingStatus === 'loading', 'auth-loading')
	useShowErrorMessageEffect(loadingStatus === 'error', 'Не удалось создать пароль');

	if (isConfirmed) {
		return (
			<div className={cl.confirmWrapper}>
				<div className={cl.confirmedIcon}>
					<CheckRoundConfirmedIcon />
				</div>

				<div className={cl.formTitle}>Создание прошло успешно</div>

				{/*<div className={cl.descr}>*/}
				{/*	Обращаем ваше внимание, что у сайта не&#160;предусмотрена мобильная версия.*/}
				{/*	Рекомендуем продолжать работу с компьютера.*/}
				{/*</div>*/}

				<MyButton
					addClasses={cl.loginFormButton}
					onClick={() => redirectLink && window.location.replace(redirectLink)}
				>
					Продолжить
				</MyButton>
			</div>
		);
	}

	return (
		<form className={cl.container} onSubmit={e => e.preventDefault()}>
			<div className={cl.inputsWrapper}>
				<div className={cl.formTitle}>Создание пароля</div>

				<div className={cl.descr}>
					Придумайте пароль, который будет использоваться для входа на платформу
				</div>

				<MyInput
					onChange={e => setPassword(e.target.value)}
					value={password}
					type='password'
					placeholder='Пароль'
					isError={isWrongPassword}
				/>
				{isWrongPassword && (
					<div className={cl.errorMessage}>Введите пароль. Минимум 5 символов</div>
				)}
			</div>

			<div className={cl.buttonsWrapper}>
				<MyButton
					addClasses={cl.loginFormButton}
					onClick={() => {
						passwordSchema
							.validate(password)
							.then(() => {
								setIsWrongPassword(false);
								confirm();
							})
							.catch(() => setIsWrongPassword(true));
					}}
					disabled={loadingStatus === 'loading' || password.length < 1}
				>
					Создать
				</MyButton>
			</div>
		</form>
	);
};

export default ConfirmForm;
