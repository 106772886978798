import cl from './logo.module.scss';

const Logo = () => {
    return (
        <div className={cl.logo}>

        </div>
    );
};

export default Logo;