import {useEffect} from "react";
import {closeLoadingStatus, showLoadingStatus} from "@uiComponents/ReactToastify/ReactToastify.tsx";
import SpinnerCircle from "@uiComponents/Spinner/SpinnerCircle.tsx";

export function useShowLoadingStatusEffect (isLoading: boolean, id: string) {
    useEffect(() => {
        if (isLoading) {
            showLoadingStatus(<SpinnerCircle/>, id)
        } else {
            closeLoadingStatus(id)
        }
        return () => {closeLoadingStatus(id)}
    }, [isLoading, id]);
}