import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { emailSchema } from '@components/Schemas/Schemas.ts';

import Logo from '@uiComponents/Logo/Logo';
import MyButton from '@uiComponents/MyButton/MyButton';
import MyInput from '@uiComponents/MyInput/MyInput';
import { showToastMessage } from '@uiComponents/ReactToastify/ReactToastify.tsx';
import Tabs from '@uiComponents/Tabs/Tabs';

import { useQuery } from '@hooks/query.hook.ts';
import { useShowLoadingStatusEffect } from '@hooks/useShowLoadingStatusEffect.tsx';

import { useAuthService } from '@services/useAuthService.ts';

import cl from './restorePage.module.scss';

const RestorePage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const clientId = useQuery('clientId');
	const redirectUrl = useQuery('redirectUrl');

	useEffect(() => {
		if (clientId) {
			localStorage.setItem('clientId', clientId);
		}
	}, [clientId]);

	const [activeTab, setActiveTab] = useState<string>('');
	const [isCodeSended, setIsCodeSended] = useState<boolean>(false);
	const [code, setCode] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [isWrongEmail, setIsWrongEmail] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const [availableAuthMethods, setAvailableAuthMethods] = useState<string[]>([]);

	const {
		getClientInfo,
		getRedirectLink,
		passwordlessConfirm,
		passwordlessStart,
		loadingStatus
	} = useAuthService();

	useShowLoadingStatusEffect(loadingStatus === 'loading', 'restoreService');

	useEffect(() => {
		(async () => {
			if (!clientId) return;

			const response = await getClientInfo(clientId);
			setAvailableAuthMethods(response.authMethods);
		})();
	}, []);

	const restorePassword = async () => {
		switch (activeTab) {
			case 'email':
				if (email.length > 0) {
					await sendCodeToEmail(email);
					break;
				} else {
					showToastMessage('Пожалуйста, введите  email');
					break;
				}

			case 'phone_code':
				await sendCodeToPhone(phone);
				break;
		}
	};

	const sendCodeToPhone = async (phone: string) => {
		if (isCodeSended) {
			const response = await passwordlessConfirm(clientId as string, {
				realm: 'sms',
				username: phone,
				otp: code
			});

			return window.location.replace(
				getRedirectLink(redirectUrl as string, {
					...response
				})
			);
		}

		try {
			await passwordlessStart(clientId as string, {
				connection: 'sms',
				phone_number: phone
			});
			setIsCodeSended(true);
		} catch (e) {
			// if e is SpecifyRoleToAuthorize - show role input and resend request with a role
		}
	};

	const sendCodeToEmail = async (email: string) => {
		if (isCodeSended) {
			return;
		}

		try {
			await passwordlessStart(clientId as string, {
				connection: 'email',
				email
			});
			setIsCodeSended(true);
			showToastMessage('Письмо отправлено на Ваш email');
		} catch (e) {
			showToastMessage('Не удалось отправить письмо на Ваш email');
			// if e is SpecifyRoleToAuthorize - show role input and resend request with a role
			// if (e.response.data.errorContent.email[0] === "NOT_EMPTY") {}
		}
	};

	const tabsItems = useMemo(() => {
		const tabs = [];

		if (availableAuthMethods.includes('phone_code')) {
			tabs.push({
				id: 'phone_code',
				// title: 'Телефон',
				content: (
					<div className={cl.inputsWrapper}>
						{isCodeSended ? (
							<div className={cl.inputsWrapper}>
								<MyInput
									onChange={e => setCode(e.target.value)}
									value={code}
									type='text'
									placeholder='Code'
									addClasses={cl.restoreFormInput}
								/>
								<MyInput
									onChange={e => setPassword(e.target.value)}
									value={password}
									type='password'
									placeholder='Password'
									addClasses={cl.restoreFormInput}
								/>
							</div>
						) : (
							<MyInput
								onChange={e => setPhone(e.target.value)}
								value={phone}
								type='phone'
								placeholder='Phone'
								addClasses={cl.restoreFormInput}
							/>
						)}
					</div>
				)
			});
		}

		if (availableAuthMethods.includes('email')) {
			tabs.push({
				id: 'email',
				// title: 'Почта',
				content: (
					<div className={cl.inputsWrapper}>
						<MyInput
							onChange={e => setEmail(e.target.value)}
							value={email}
							type='email'
							placeholder='Email'
							isError={isWrongEmail}
						/>
						{isWrongEmail && (
							<div className={cl.errorMessage}>
								Неверный формат почты. Допустимый формат почты - example@gmail.com
							</div>
						)}
					</div>
				)
			});
		}

		if (!activeTab) {
			setActiveTab(tabs[0]?.id);
		}

		return tabs;
	}, [isWrongEmail, email, phone, code, password, activeTab, availableAuthMethods, isCodeSended]);

	if (loadingStatus === 'loading') {
		return <></>;
	}

	return (
		<div className={cl.container}>
			<Logo />

			{isCodeSended ? (
				<div className={cl.emailSended}>
					<div className={cl.title}>Письмо на почту отправлено</div>
					<div className={cl.descr}>
						Перейдите по ссылке в письме, которое было отправлено Вам на почту,
						для продолжения восстановления
					</div>
				</div>
			) : (
				<div>
					<div className={cl.title}>Восстановить пароль</div>

					<form
						className={cl.restoreForm}
						onSubmit={e => e.preventDefault()}
						noValidate={true}
					>
						<Tabs activeTab={activeTab} setActiveTab={setActiveTab} items={tabsItems} />

						<div className={cl.buttonsWrapper}>
							{!isCodeSended && (
								<MyButton
									onClick={() => {
										emailSchema
											.validate(email)
											.then(() => {
												setIsWrongEmail(false);
												restorePassword();
											})
											.catch(() => setIsWrongEmail(true));
									}}
									disabled={email.length < 1}
								>
									Отправить
								</MyButton>
							)}

							<a
								className={cl.buttonLink}
								onClick={e => {
									e.preventDefault();
									navigate(`/auth${location.search}`);
								}}
							>
								На страницу входа
							</a>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default RestorePage;
