import { IAuthResponse } from '@models/response/IAuthResponse.ts';
import {useHttp} from "@hooks/http.hook.ts";

export type ClientInfo = {
    id: string;
    authMethods: Array<'email' | 'phone' | 'phone_code'>;
};

export type LoginRequestData = { type: 'email'; email: string; password: string }
    | { type: 'phone'; phone: string; password: string };

export type PasswordlessStartRequest = { role?: string } & (
    | { connection: 'email'; email: string }
    | { connection: 'sms'; phone_number: string } )

export type PasswordlessConfirmRequest = { realm: 'email' | 'sms'; username: string; otp: string; password?: string }

export const useAuthService = () => {
    const {request, loadingStatus, setLoadingStatus} = useHttp();

    const getRedirectLink = (
        url: string,
        query: {
            authorizationCode: string;
            userId: string;
            redirectUrl?: string;
        }
    ): string => {
        const params = new URLSearchParams(query);
        return `${url}?${params.toString()}`;
    }

    const getSessionInfo = async (): Promise<{ userId: string; email: string | null; phone: string | null }> => {
        const response = await request({
            url: '/user/session',
            method: 'GET',
        })
        return response.data
    }

    const getClientInfo = async (clientId: string): Promise<ClientInfo> => {
        const response = await request({
            url: `/oauth?clientId=${clientId}`,
            method: 'GET',
        })
        return response.data
    }

    const login = async (clientId: string, data: LoginRequestData): Promise<IAuthResponse> => {
        const response = await request({
            url: `/oauth/login?clientId=${clientId}`,
            method: 'POST',
            body: data
        })
        return response.data
    }

    const loginSession = async (clientId: string): Promise<IAuthResponse> => {
        const response = await request({
            url: `/oauth/session?clientId=${clientId}`,
            method: 'POST',
        })
        return response.data
    }

    const passwordlessStart = async (clientId: string, data: PasswordlessStartRequest): Promise<void> => {
        const response = await request({
            url: `/passwordless/start?clientId=${clientId}`,
            method: 'POST',
            body: data
        })
        return response.data
    }

    const passwordlessConfirm = async (clientId: string, data: PasswordlessConfirmRequest): Promise<IAuthResponse> => {
        const response = await request({
            url: `/passwordless/confirm?clientId=${clientId}`,
            method: 'POST',
            body: data
        })
        return response.data
    }

    return {
        getRedirectLink,
        getSessionInfo,
        getClientInfo,
        login,
        loginSession,
        passwordlessStart,
        passwordlessConfirm,
        loadingStatus,
        setLoadingStatus
    }
}