import { useEffect, useState } from 'react';
import cl from './sessionLogin.module.scss';
import MyButton from '../../../../components/UI/MyButton/MyButton';
import {useAuthService} from "@services/useAuthService.ts";

const SessionLogin = ({
    clientId,
    redirectUrl,
    backRedirectUrl
}: {
    clientId: string;
    redirectUrl: string;
    backRedirectUrl?: string;
}) => {
    const [sessionInfo, setSessionInfo] = useState<{
        userId: string;
        email: string | null;
        phone: string | null;
    } | null>(null);

    const {getSessionInfo, loginSession: loginSessionService, getRedirectLink, loadingStatus,
        setLoadingStatus} = useAuthService();

    useEffect(() => {
        (async () => {
            try {
                const response = await getSessionInfo();
                setSessionInfo(response);
            } catch (err) {
                //
            }
        })();
    }, []);

    const loginSession = async () => {
        try {
            const response = await loginSessionService(clientId);

            setLoadingStatus('loading')
            window.location.replace(
                getRedirectLink(redirectUrl, {
                    ...response,
                    redirectUrl: backRedirectUrl
                })
            );
        } catch (e) {
            //
        }
    };

    if (!sessionInfo) {return <></>;}

    return (
        <div className={cl.sessionLogin}>
            <MyButton onClick={loginSession}>
                Войти как {sessionInfo?.email ?? sessionInfo?.phone}
            </MyButton>
            <MyButton onClick={() => setSessionInfo(null)}>Другой аккаунт</MyButton>
        </div>
    );
};

export default SessionLogin;
