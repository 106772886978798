import { useEffect } from 'react';

import IndexDescription from '@uiComponents/IndexDescription/IndexDescription.tsx';
import Logo from '@uiComponents/Logo/Logo.tsx';

import { useQuery } from '@hooks/query.hook.ts';

import cl from './authPage.module.scss';

import ConfirmForm from './components/ConfirmForm/ConfirmForm.tsx';
import LoginForm from './components/LoginForm/LoginForm.tsx';

const AuthPage = () => {
	const clientId = useQuery('clientId');
	const redirectUrl = useQuery('redirectUrl');
	const backRedirectUrl = useQuery('backRedirectUrl');
	const username = useQuery('username');
	const realm = useQuery<'email' | 'sms'>('realm');
	const otp = useQuery('otp');

	useEffect(() => {
		if (clientId) {
			localStorage.setItem('clientId', clientId);
		}
	}, [clientId]);

	if (!clientId || !redirectUrl) {
		return <></>;
	}

	return (
		<div className={cl.container}>
			<Logo />

			<div className={cl.wrapper}>
				{realm && otp && username ? (
					<>
						<ConfirmForm
							clientId={clientId}
							realm={realm}
							username={username}
							otp={otp}
							redirectUrl={redirectUrl}
						/>
					</>
				) : (
					<>
						{clientId && redirectUrl && (
							<>
								<LoginForm
									clientId={clientId}
									redirectUrl={redirectUrl}
									backRedirectUrl={backRedirectUrl}
								/>

								<IndexDescription type='auth'/>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default AuthPage;
