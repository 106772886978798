import React from 'react';
import cl from './tabs.module.scss';

import TabNavItem from './TabNavItem';
import TabContent from './TabContent';

const Tabs = ({
    activeTab,
    setActiveTab,
    items
}: {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    items: { id: string; title?: string; content: React.ReactNode }[];
}) => {
    return (
        <div className={cl.tabs}>
            <ul className={cl.tabs__nav}>
                {items.map((item) => (
                    <TabNavItem
                        key={item.id}
                        title={item.title}
                        id={item.id}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                ))}
            </ul>

            <div className={cl.tabs__items}>
                {items.map((item) => (
                    <TabContent key={item.id} id={item.id} activeTab={activeTab}>
                        {item.content}
                    </TabContent>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
