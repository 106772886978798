import { RouteObject } from 'react-router';

import AuthPage from '@/pages/AuthPage/AuthPage.tsx';
import ErrorPage from '@/pages/ErrorPage/ErrorPage.tsx';
import RestorePage from '@/pages/RestorePage/RestorePage.tsx';

export const routes: RouteObject[] = [
	{
		path: '/',
		errorElement: <ErrorPage type='404'/>
	},
	{
		path: '/auth',
		element: <AuthPage />
	},
	{
		path: '/restore',
		element: <RestorePage />
	},
	// {
	// 	path: '/registry',
	// 	element: <RegistryPage />
	// }
];
